<template>
  <div id="modal-drawer-league" class="modal fade fixed-end" tabindex="-1" role="dialog">
    <div role="document" class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4">
      <validation-observer tag="form" class="modal-content" @submit.prevent>
        <div class="modal-header">
          <h1 class="modal-title">{{ drawerTitle }}</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div><!-- /.modal-header -->

        <div class="modal-body">
          <validation-provider tag="div" name="profession" class="mb-4" rules="required">
            <label class="form-label">Profession</label>
            <profession-select type="text" v-model="stateProfessionId" />
            <c-help-block/>
          </validation-provider>

          <validation-provider tag="div" name="name" class="mb-4" rules="required">
            <label class="form-label">Name</label>
            <c-input type="text" v-model="stateName" />
            <c-help-block/>
          </validation-provider>

          <validation-provider tag="div" name="abbreviation" class="mb-4" rules="required">
            <label class="form-label">Abbreviation</label>
            <c-input type="text" v-model="stateAbbreviation" />
            <c-help-block/>
          </validation-provider>
        </div>

        <div class="modal-footer">
          <template v-if="editingQualification">
            <c-button
              v-if="qualification.status === 'published'"
              variant="outline-danger"
              class="me-auto"
              @click="toggleQualificationStatus"
            >
              Archive
            </c-button>

            <c-button
              v-else
              variant="outline-success"
              class="me-auto"
              @click="toggleQualificationStatus"
            >
              Restore
            </c-button>
          </template>

          <c-button validate variant="primary" @click="save">
            {{ editingQualification ? 'Save' : 'Create' }}
          </c-button>
        </div>
      </validation-observer>
    </div>
  </div><!-- /.modal-drawer -->
</template>

<script>
import { CModal, toast } from '@chameleon/chameleon-vue'
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/qualifications'
import { get, call } from 'vuex-pathify'
import ProfessionSelect from '@/components/professions/ProfessionSelect'

export default {
  extends: CModal,
  mixins: [stateMapper],

  components: {
    ProfessionSelect,
  },

  data () {
    return {
      optionsStatus: [
        { label: 'Published', value: 'published' },
        { label: 'Archived', value: 'archived' },
      ]
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
    })

    this.addEventListener('close', async () => {
      this.resetQualification()
    })
  },

  computed: {
    ...helperComputed,
    ...get('accreditationBodies', ['accreditationBody']),

    drawerTitle () {
      return this.editingQualification ? 'Edit Qualification' : 'Add Qualification'
    },

    editingQualification () {
      return this.qualification?.id !== null
    },
  },

  beforeDestroy () {
    this.resetQualification()
  },

  methods: {
    ...helperMethods,
    toggleStatus: call('qualifications/toggleStatus'),

    async save () {
      const payload = {
        name: this.qualification.name,
        abbreviation: this.qualification.abbreviation,
        profession_id: this.qualification.profession_id,
      }

      if (this.editingQualification) {
        await this.updateQualification({ id: this.qualification.id, payload: { ...payload } })
      } else {
        await this.storeQualification({ ...payload })
      }

      this.$emit('refresh')
      toast('Saved successfully', 'success')
      this.close()
    },

    async toggleQualificationStatus () {
      await this.toggleStatus({ id: this.qualification.id })
      this.$emit('refresh')
    },
  }
}
</script>
