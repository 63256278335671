<template>
  <div class="modal fade fixed-end" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <validation-observer tag="div" class="modal-content" @submit.native.prevent>
        <div class="modal-header">
          <h2 class="modal-title">{{ drawerTitle }}</h2>

          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="close"
          />
        </div>

        <div class="modal-body">
          <validation-provider tag="div" name="profession" vid="profession_id" rules="required" class="mb-3" >
            <label class="form-label">Profession</label>
            <profession-select v-model="stateProfessionId" />
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" name="name" vid="name" rules="required" class="mb-3" >
            <label class="form-label">Name</label>
            <c-input v-model="stateName"/>
            <c-help-block />
          </validation-provider>
        </div>

        <div class="modal-footer">
          <template v-if="!isNewCpdBody">
            <c-button
              v-if="!cpdBody.archived"
              variant="outline-danger"
              class="me-auto"
              @click="archive"
            >
              Archive
            </c-button>

            <c-button
              v-else
              variant="outline-success"
              class="me-auto"
              @click="restore"
            >
              Restore
            </c-button>
          </template>

          <button class="btn btn-outline-info me-2" @click="close">
            Cancel
          </button>

          <c-button variant="success" @click="submit" validate>
            Save
          </c-button>
        </div>
      </validation-observer>
    </div>
  </div>

</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/cpd-bodies'
import { CModal, toast } from '@chameleon/chameleon-vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'

export default {
  extends: CModal,

  mixins: [stateMapper],

  components: {
    ProfessionSelect,
  },

  computed: {
    ...helperComputed,

    isNewCpdBody () {
      return !this.cpdBody?.id
    },

    drawerTitle () {
      return this.isNewCpdBody
        ? 'Add CPD Body'
        : 'Edit CPD Body'
    },
  },

  methods: {
    ...helperMethods,

    async submit () {
      if (this.isNewCpdBody) {
        await this.storeCpdBody(this.cpdBody)

        toast('CPD Body created successfully')
      } else {
        await this.updateCpdBody(this.cpdBody)

        toast('CPD Body updated successfully')
      }

      this.$emit('refresh')
      this.close()
    },

    async archive () {
      await this.deleteCpdBody(this.cpdBody.id)

      this.$emit('refresh')
      this.close()
    },

    async restore () {
      await this.updateCpdBody(this.cpdBody)

      this.$emit('refresh')
      this.close()
    },
  },
}
</script>
