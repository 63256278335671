<template>
  <div class="modal fade fixed-end" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <validation-observer tag="div" class="modal-content" @submit.native.prevent>
        <div class="modal-header">
          <h2 class="modal-title">Add Accreditation Body</h2>

          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="close"
          />
        </div>

        <div class="modal-body">
          <validation-provider tag="div" name="profession" vid="profession_id" rules="required" class="mb-3" >
            <label class="form-label">Profession</label>
            <profession-select v-model="stateProfessionId" />
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" name="name" vid="name" rules="required" class="mb-3" >
            <label class="form-label">Name</label>
            <c-input v-model="stateName"/>
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" name="acronym" vid="body_acronym" class="mb-3" >
            <label class="form-label">Acronym</label>
            <c-input v-model="stateBodyAcronym"/>
            <c-help-block />
          </validation-provider>
        </div>

        <div class="modal-footer">
          <button class="btn btn-outline-info me-2" @click="close">
            Cancel
          </button>

          <c-button variant="success" @click="submit" validate>
            Save
          </c-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/accreditation-bodies'
import { CModal } from '@chameleon/chameleon-vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'

export default {
  extends: CModal,

  mixins: [stateMapper],

  components: {
    ProfessionSelect,
  },

  computed: {
    ...helperComputed,
  },

  methods: {
    ...helperMethods,

    async submit () {
      const response = await this.storeAccreditationBody({ ...this.accreditationBody })

      this.$router.push({
        name: 'settings.accreditation-bodies.single',
        params: {
          accreditationBodyId: response.id,
        },
      })
    },
  }
}
</script>
