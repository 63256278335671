<template>
  <li :class="{ 'sub': !item.is_profession }">
    <a @click.prevent="openEdit">
      <div class="row">
        <div class="col-lg">
          <p>{{ item.name }}</p>
        </div>

        <div v-if="!item.is_profession" class="col col-status">
          <span v-if="item.archived" class="status status-danger">Archived</span>
          <span v-else class="status status-success">Published</span>
        </div>
      </div>
    </a>
  </li>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  methods: {
    openEdit () {
      if (this.item.is_profession) {
        return
      }

      this.$emit('open-edit-cpd-bodies-drawer', this.item)
    }
  },
}
</script>
