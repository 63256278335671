import { render, staticRenderFns } from "./TopicsListFilters.vue?vue&type=template&id=2fe928a4&"
import script from "./TopicsListFilters.vue?vue&type=script&lang=js&"
export * from "./TopicsListFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports