<template>
  <li :class="{ 'sub': !item.is_profession }">
    <a  @click.prevent="openDrawer">
      <div class="row">
        <div class="col">
          <span v-if="!item.is_profession" class="me-1">{{ item.abbreviation }} - </span> {{ item.name }}
        </div>
        <div v-if="!item.is_profession" class="col col-status">
          <span v-if="item.status === 'published'" class="status status-success">Published</span>
          <span v-else class="status status-danger">Archived</span>
        </div>
      </div>
    </a>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },

  methods: {
    openDrawer () {
      if (this.item.is_profession) {
        return
      }

      this.$emit('open-drawer', this.item)
    }
  },
}
</script>
