<template>
  <fragment>
    <app-header :title="pageTitle">
      <template #actions>
        <button
          @click.prevent="openAddDrawer"
          class="btn btn-md btn-primary"
        >
          <i class="far fa-plus" /> Qualification
        </button>
      </template>
    </app-header>

    <div class="container">
      <qualification-list-filters v-model="params" />

      <c-infinite-scroll
        as="ul"
        class="list-data list-data-setting-topic-categories has-button"
        @load="load"
      >
        <li class="data-heading d-none d-lg-block">
          <div class="row">
            <div class="col-lg">
            <p>Qualification and Profession</p>
            </div>
            <div class="col col-status">
              <p>Status</p>
            </div>
          </div>
        </li>

        <qualification-list-item
          v-for="item in qualificationsFlattened"
          :key="`${item.is_profession ? 'profession' : 'accreditation-body'}-${item.id}`"
          :item="item"
          @open-drawer="openEditDrawer"
        />
      </c-infinite-scroll>
    </div>

    <qualifications-single-drawer ref="qualificationsSingleDrawer" @refresh="refresh" />
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/qualifications'
import { compareQueryParams } from '@codium/codium-vue'
import QualificationsSingleDrawer from '@/components/qualifications/QualificationsSingleDrawer'
import QualificationListItem from '@/components/qualifications/QualificationListItem'
import QualificationListFilters from '@/components/qualifications/QualificationListFilters'

export default {
  components: {
    QualificationsSingleDrawer,
    QualificationListItem,
    QualificationListFilters,
  },

  data () {
    return {
      params: null,
    }
  },

  watch: {
    '$route.query': {
      handler: async function () {
        await this.getQualifications({ params: { status: this.$route.query.status }, fresh: true })
      },
    },

    params: compareQueryParams(async function (params) {
      await this.getQualifications({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed,

    pageTitle () {
      return 'Qualifications'
    },

    qualificationsFlattened () {
      const flattened = []

      this.qualifications.forEach((profession) => {
        flattened.push({
          ...profession,
          is_profession: true,
        })

        if ((profession.qualifications?.length ?? 0) > 0) {
          profession.qualifications.forEach((qualification) => {
            flattened.push({
              ...qualification,
              is_profession: false,
            })
          })
        }
      })

      return flattened
    },
  },

  beforeDestroy () {
    this.resetQualifications()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      try {
        await this.getQualifications({ params: this.$route.query })
        done()
      } catch {
        done(true)
      }
    },

    async refresh () {
      await this.getQualifications({ params: this.$route.query, fresh: true })
    },

    openAddDrawer (qualification) {
      this.resetQualification()
      this.$refs.qualificationsSingleDrawer.open()
    },

    openEditDrawer (qualification) {
      this.setQualification(qualification)
      this.$refs.qualificationsSingleDrawer.open()
    },
  }
}
</script>
