<template>
  <fragment>
    <app-header :title="pageTitle">
      <template #actions>
        <button class="btn btn-md btn-primary" @click="openCreateAccreditationBodyDrawer">
          <i class="far fa-plus" /> Accreditation Body
        </button>
      </template>
    </app-header>

    <div class="container">
      <accreditation-bodies-list-filters v-model="params" />

      <c-infinite-scroll
        as="ul"
        class="list-data list-data-setting-topic-categories has-button"
        @load="load"
      >
        <li class="data-heading d-none d-lg-block">
          <div class="row">
            <div class="col-lg">
            <p>Accreditation Body and Profession</p>
            </div>
            <div class="col col-status">
              <p>Status</p>
            </div>
          </div>
        </li>

        <list-item
          v-for="item in accreditationBodiesFlattened"
          :key="`${item.is_profession ? 'profession' : 'accreditation-body'}-${item.id}`"
          :item="item"
        />
      </c-infinite-scroll>
    </div>

    <accreditation-bodies-drawer ref="accreditationBodiesDrawer" />
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/accreditation-bodies'
import { compareQueryParams } from '@codium/codium-vue'
import ListItem from '@/components/accreditation-bodies/AccreditationBodiesListItem.vue'
import AccreditationBodiesListFilters from '@/components/accreditation-bodies/AccreditationBodiesListFilters.vue'
import AccreditationBodiesDrawer from '@/components/accreditation-bodies/AccreditationBodiesDrawer.vue'

export default {
  components: {
    ListItem,
    AccreditationBodiesListFilters,
    AccreditationBodiesDrawer,
  },

  data () {
    return {
      pageTitle: 'Accreditation Bodies',
      params: null,
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getAccreditationBodies({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed,

    accreditationBodiesFlattened () {
      const flattened = []

      this.accreditationBodies.forEach((profession) => {
        flattened.push({
          ...profession,
          is_profession: true,
        })

        if ((profession.accreditation_bodies?.length ?? 0) > 0) {
          profession.accreditation_bodies.forEach((accreditationBody) => {
            flattened.push({
              ...accreditationBody,
              is_profession: false,
            })
          })
        }
      })

      return flattened
    },
  },

  beforeDestroy () {
    this.resetAccreditationBodies()
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getAccreditationBodies({ params })

        done()
      } catch {
        done(true)
      }
    },

    async openCreateAccreditationBodyDrawer () {
      await this.resetAccreditationBody()
      this.$refs.accreditationBodiesDrawer.open()
    },
  }
}
</script>
