<template>
  <fragment>
    <app-header title="Settings" />

    <div class="container">
      <ul class="nav nav-grid">
        <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.primary-fields' }">
            <i class="far fa-hospital-user"></i>
            <div>
                Profession and Primary Fields
              <span class="description">
                Setup options available for selection as a medical professional's
                profession and primary field.
              </span>
            </div>
          </router-link>
        </li>

         <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.universities' }">
            <i class="far fa-graduation-cap"></i>
            <div>
                Universities
              <span class="description">Setup options available for selection as a medical professional's university.</span>
            </div>
          </router-link>
        </li>

        <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.topics' }">
            <i class="far fa-books"></i>
            <div>
              Topics and Subtopics
              <span class="description">Setup options available for courses and providers.</span>
            </div>
          </router-link>
        </li>

        <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.accreditation-bodies' }">
            <i class="far fa-university"></i>
            <div>
              Accreditation Bodies
              <span class="description">
                Define related information for each accreditation body.
              </span>
            </div>
          </router-link>
        </li>

        <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.qualifications' }">
            <i class="far fa-university"></i>
            <div>
              Qualifications
              <span class="description">
                Define the qualifications available within the system.
              </span>
            </div>
          </router-link>
        </li>

        <li class="nav-item col-6 col-md-4 col-xl-3">
          <router-link class="nav-link" :to="{ name: 'settings.cpd-bodies' }">
            <i class="far fa-university"></i>
            <div>
              CPD Body
              <span class="description">
                Define the CPD Bodies available within the system for a medical professional to select.
              </span>
            </div>
          </router-link>
        </li>
      </ul>
    </div>

    <slot name="footer" />
  </fragment>
</template>

<script>
export default {
}
</script>
