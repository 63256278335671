<template>
  <fragment>
      <app-header title="Profession & Primary Field">
        <template #actions>
          <button
            type="button"
            class="btn btn-primary me-2"
            @click="openCreateProfessionDrawer"
          >
            <i class="far fa-plus" /> Profession
          </button>

          <button
            type="button"
            class="btn btn-primary"
            @click="openCreatePrimaryFieldDrawer"
          >
            <i class="far fa-plus" /> Primary Field
          </button>
        </template>
      </app-header>

      <div class="container">
        <primary-fields-list-filters v-model="params" />

        <c-infinite-scroll
          @load="load"
          as="ul"
          class="list-data list-data-setting-topic-categories has-button"
          scroll_target=".container"
      >
          <li class="data-heading d-none d-lg-block">
            <div class="row">
              <div class="col-lg">
              <p>Profession &amp; Primary Field</p>
              </div>
              <div class="col col-status">
                <p>Status</p>
              </div>
            </div>
          </li>

          <primary-fields-list-item
            v-for="item in primaryFieldsFlattened"
            :key="`${item.is_profession ? 'profession' : 'primary-field'}-${item.id}`"
            :item="item"
            @open-edit-profession-drawer="openEditProfessionDrawer"
            @open-edit-primary-field-drawer="openEditPrimaryFieldDrawer"
          />
        </c-infinite-scroll>
    </div>

    <primary-fields-profession-drawer ref="professionDrawer" @refresh-list="refreshList" />

    <primary-fields-drawer ref="primaryFieldDrawer" @refresh-list="refreshList" />
  </fragment>
</template>

<script>
import { call } from 'vuex-pathify'
import { helperComputed, helperMethods } from '@/vuex/modules/primary-fields'
import { compareQueryParams } from '@codium/codium-vue'
import PrimaryFieldsListFilters from '@/components/primary-fields/PrimaryFieldsListFilters'
import PrimaryFieldsListItem from '@/components/primary-fields/PrimaryFieldsListItem'
import PrimaryFieldsProfessionDrawer from '@/components/primary-fields/PrimaryFieldsProfessionDrawer'
import PrimaryFieldsDrawer from '@/components/primary-fields/PrimaryFieldsDrawer'

export default {
  components: {
    PrimaryFieldsListFilters,
    PrimaryFieldsListItem,
    PrimaryFieldsProfessionDrawer,
    PrimaryFieldsDrawer,
  },

  data () {
    return {
      params: null,
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getPrimaryFields({ params, fresh: true })
    })
  },

  computed: {
    ...helperComputed,

    primaryFieldsFlattened () {
      const flattened = []

      this.primaryFields.forEach((profession) => {
        flattened.push({
          ...profession,
          is_profession: true,
        })

        if ((profession.primary_fields?.length ?? 0) > 0) {
          profession.primary_fields.forEach((primaryField) => {
            flattened.push({
              ...primaryField,
              is_profession: false,
            })
          })
        }
      })

      return flattened
    },
  },

  methods: {
    ...helperMethods,
    ...call('professions', ['setProfession', 'resetProfession']),
    ...call('lists/professions', ['getProfessions']),

    async load (done) {
      const { params } = this

      try {
        await this.getPrimaryFields({ params })

        done()
      } catch {
        done(true)
      }
    },

    async openCreateProfessionDrawer () {
      await this.resetProfession()
      this.$refs.professionDrawer.open()
    },

    async openCreatePrimaryFieldDrawer () {
      await this.resetPrimaryField()
      this.$refs.primaryFieldDrawer.open()
    },

    async openEditProfessionDrawer (profession) {
      await this.setProfession(profession)
      this.$refs.professionDrawer.open()
    },

    async openEditPrimaryFieldDrawer (primaryField) {
      await this.setPrimaryField(primaryField)
      this.$refs.primaryFieldDrawer.open()
    },

    async refreshList () {
      await this.getPrimaryFields({ params: this.params, fresh: true })
      await this.getProfessions({ fresh: true })
    },
  }
}
</script>
