<template>
  <fragment>
    <app-header v-bind="{ title }" />
    <div class="container container-max-md">
      <!-- accreditation body card -->
      <validation-observer tag="form" class="card" @submit.prevent>
        <div class="card-body">
          <validation-provider tag="div" class="mb-3" name="name" vid="name" rules="required">
            <label class="form-label">Name</label>
            <c-input v-model="stateName" />
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" class="mb-3" name="acronym" vid="body_acronym" rules="required">
            <label class="form-label">Acronym</label>
            <c-input v-model="stateBodyAcronym" />
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" class="mb-3" name="profession" vid="profession_id" rules="required">
            <label class="form-label">Profession</label>
            <profession-select v-model="stateProfessionId" />
            <c-help-block />
          </validation-provider>

          <div class="mb-3">
            <label class="form-label">Logo</label>
            <input
              type="file"
              class="form-control"
              @change="fileSelectionChanged"
            />
            <small class="form-text">
              Maximum 5MB. File types: jpg, gif, png. Optimal image dimension: 512 x 512 pixels
            </small>
            <div class="file-details" v-if="accreditationBody.logo">
              <span class="extension">{{
                accreditationBody.logo.extension.toUpperCase()
              }}</span>
              <span class="filename">{{ accreditationBody.logo.name }}</span>
              <span class="file-size">{{ accreditationBody.logo.size }}MB</span>
              <a
                class="remove-file"
                href=""
                @click.prevent="
                  deleteAccreditationBodyLogo(accreditationBody.id)
                "
              >
                <i class="fa fa-trash-o"></i> Delete File
              </a>
              <c-button
                class="btn-sm btn-download"
                variant="outline-primary"
                @click="downloadLogo"
              >
                <i class="far fa-cloud-download me-1"></i>
                <span class="d-none d-lg-inline">Download</span>
              </c-button>
            </div>
          </div>

          <div class="row">
            <div class="col d-flex align-items-center" v-if="accreditationBody.status">
              <c-button
                v-if="accreditationBody.status === 'published'"
                class="btn-sm"
                variant="outline-warning"
                @click="archive"
              >
                <i class="far fa-archive"></i> Archive
              </c-button>
              <c-button
                v-else
                class="btn-sm"
                variant="outline-info"
                @click="restore"
              >
                <i class="far fa-undo"></i> Publish
              </c-button>
            </div>
            <div class="col-auto">
              <c-button class="btn-md" @click="submit" validate>
                Save Changes
              </c-button>
            </div>
          </div>
        </div>
      </validation-observer>
    </div>
  </fragment>
</template>

<script>
import { call, get, sync } from 'vuex-pathify'
import { http } from '@/utils'
import { stateMapper } from '@/vuex/modules/accreditation-bodies'
import { toast } from '@chameleon/chameleon-vue'
import { downloadBlob } from '@/utils/functions/download-blob'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'

export default {
  props: {
    accreditationBodyId: Number
  },

  components: {
    ProfessionSelect,
  },

  mixins: [stateMapper],

  data () {
    return {
      title: null,
      file: null,
    }
  },

  computed: {
    accreditationBody: get('accreditationBodies/accreditationBody'),
    qualifications: sync('accreditationBodies/accreditationBody@qualifications'),
  },

  async created () {
    await this.getAccreditationBody(this.accreditationBodyId)

    this.title = this.accreditationBody.name
  },

  beforeDestroy () {
    this.resetAccreditationBody()
  },

  methods: {
    updateAccreditationBody: call('accreditationBodies/updateAccreditationBody'),
    getAccreditationBody: call('accreditationBodies/getAccreditationBody'),
    resetAccreditationBody: call('accreditationBodies/resetAccreditationBody'),
    getAccreditationBodyLogo: call('accreditationBodies/getAccreditationBodyLogo'),
    deleteAccreditationBodyLogo: call('accreditationBodies/deleteAccreditationBodyLogo'),

    async downloadLogo () {
      const response = await http.get(this.accreditationBody.logo.url, { responseType: 'blob' })

      downloadBlob(response.data, this.accreditationBody.logo.name)
    },

    async fileSelectionChanged (event) {
      if (event.target.files.length === 0) {
        this.file = null

        return
      }

      this.file = event.target.files.item(0)
    },

    async archive () {
      try {
        await http.put(`/accreditation-bodies/${this.accreditationBody.id}/archive`)
        await this.getAccreditationBody(this.accreditationBodyId)
        toast('Accreditation body archived', 'success')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    async restore () {
      try {
        await http.put(`/accreditation-bodies/${this.accreditationBody.id}/restore`)
        await this.getAccreditationBody(this.accreditationBodyId)
        toast('Accreditation body published', 'success')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    async submit () {
      try {
        const payload = new FormData()

        payload.append('form', JSON.stringify({
          profession_id: this.accreditationBody.profession_id,
          name: this.accreditationBody.name,
          body_acronym: this.accreditationBody.body_acronym
        }))

        payload.append('logo', this.file)

        await this.updateAccreditationBody({
          id: this.accreditationBody.id,
          payload
        })

        this.title = this.accreditationBody.name

        toast('Accreditation body updated', 'success')
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  }
}
</script>
