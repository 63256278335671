<template>
  <div id="modal-drawer-league" class="modal fade fixed-end" tabindex="-1" role="dialog">
    <div role="document" class="modal-dialog modal-dialog-vertical dw-11 dw-md-8 dw-lg-6 dw-xl-4">
      <validation-observer tag="form" class="modal-content" @submit.prevent>
        <div class="modal-header">
          <h2 class="modal-title">{{drawerTitle}}</h2>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>

        </div><!-- /.modal-header -->
        <div class="modal-body">
            <!-- name -->
            <validation-provider tag="div" class="mb-4" name="name" rules="required">
                <label>Name</label>
                <c-input type="text" v-model="stateName" />
                <c-help-block/>
            </validation-provider>

            <!-- country -->
            <validation-provider tag="div" class="mb-4" name="country" vif="country_id" rules="required">
                <label class="form-label">Country</label>
                <country-select v-model="stateCountryId"/>
                <c-help-block />
            </validation-provider>

            <!-- status -->
            <validation-provider tag="div" class="mb-4" name="status" rules="required">
                <label class="form-label">Status</label>
                <single-select v-model="stateStatus" :options="optionsStatus" :preselect="true"/>
                <c-help-block />
            </validation-provider>
        </div>

        <!-- footer -->
        <div class="modal-footer">
            <button class="btn btn-outline-info" data-bs-dismiss="modal" aria-label="Close">Cancel</button>
            <c-button validate variant="success" @click="save">Save</c-button>
        </div>
      </validation-observer>
    </div>
  </div><!-- /.modal-drawer -->
</template>

<script>
import { CModal, toast } from '@chameleon/chameleon-vue'
import SingleSelect from '../../components/forms/SingleSelect.vue'
import CountrySelect from '../../components/forms/CountrySelect.vue'
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/universities'
import { pick } from 'lodash'

export default {
  extends: CModal,
  mixins: [stateMapper],
  components: {
    SingleSelect,
    CountrySelect
  },

  data () {
    return {
      universityId: null,
      editingUniversity: false,
      optionsStatus: [
        { label: 'Published', value: 'published' },
        { label: 'Archived', value: 'archived' },
      ]
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
      if (this.universityId) {
        await this.getUniversity(this.universityId)
        this.editingUniversity = true
      } else {
      }
    })
    this.addEventListener('close', async () => {
      this.resetUniversity()
    })
  },

  computed: {
    ...helperComputed,
    drawerTitle () {
      return this.editingUniversity ? 'Edit University' : 'Add University'
    },
  },
  beforeDestroy () {
    this.resetUniversity()
  },
  methods: {
    ...helperMethods,
    async save () {
      const payload = pick(this.university, [
        'country_id',
        'status',
        'name',
      ])

      if (this.universityId) {
        await this.updateUniversity({ id: this.universityId, payload: { ...payload } })
      } else {
        await this.storeUniversity({ ...payload })
      }
      this.$emit('refresh') // mainly for updating the list to filter new status
      toast('Saved successfully', 'success')
      this.close()
    },

    async handleDelete () {

    }
  }
}
</script>
