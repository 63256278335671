<template>
  <li :class="{ 'sub': !item.is_profession }">
    <a @click.prevent="openEditDrawer">
      <div class="row">
        <div class="col-lg">
          <p>{{ item.name }}</p>
        </div>

        <div class="col col-status">
          <span v-if="item.status === 'enabled'" class="status status-success">Enabled</span>
          <span v-else class="status status-danger">Disabled</span>
        </div>
      </div>
    </a>

    <button
      type="button"
      class="btn btn-sm text-info"
      @click="openEditDrawer"
    >
      <i class="far fa-pencil-alt" />
    </button>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  methods: {
    openEditDrawer () {
      if (this.item.is_profession) {
        this.$emit('open-edit-profession-drawer', this.item)
        return
      }

      this.$emit('open-edit-primary-field-drawer', this.item)
    }
  },
}
</script>
