<template>
  <div class="modal fade fixed-end" tabindex="-1" role="dialog">
    <div
      class="modal-dialog modal-dialog-vertical dw-11 dw-md-6 dw-lg-5 dw-xl-4"
      role="document"
    >
      <validation-observer tag="div" class="modal-content" @submit.native.prevent>
        <div class="modal-header">
          <h2 class="modal-title">{{ drawerTitle }}</h2>

          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            @click="close"
          />
        </div>
        <!-- /.modal-header -->

        <div class="modal-body">
          <validation-provider tag="div" name="profession" vid="profession_id" rules="required" class="mb-3" >
            <label class="form-label">Profession</label>
            <profession-select v-model="stateProfessionId" />
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" name="primary field" vid="name" rules="required" class="mb-3" >
            <label class="form-label">Primary Field</label>
            <c-input v-model="stateName"/>
            <c-help-block />
          </validation-provider>

          <validation-provider tag="div" name="status" vid="status" rules="required" class="mb-3" >
            <label class="form-label">Status</label>
            <c-input
              as="multiselect"
              placeholder
              :options="statusOptions"
              :preselect-first="true"
              :show-labels="false"
              :allow-empty="false"
              label="label"
              track-by="value"
              v-model="status"
            />
            <c-help-block />
          </validation-provider>
        </div>

        <div class="modal-footer">
          <c-button v-if="!isNewPrimaryField" variant="outline-danger" @click="destroy" class="me-auto">
            Delete Primary Field
          </c-button>

          <button class="btn btn-outline-info me-2" @click="close">
            Cancel
          </button>

          <c-button variant="success" @click="submit" validate>
            Save
          </c-button>
        </div>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/primary-fields'
import { CModal } from '@chameleon/chameleon-vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'
import swal from 'sweetalert'

export default {
  extends: CModal,

  mixins: [stateMapper],

  components: {
    ProfessionSelect,
  },

  data () {
    return {
      statusOptions: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Disabled', value: 'disabled' },
      ],
    }
  },

  computed: {
    ...helperComputed,

    isNewPrimaryField () {
      return this.primaryField?.id === null
    },

    drawerTitle () {
      return this.isNewPrimaryField
        ? 'Add Primary Field'
        : 'Edit Primary Field'
    },

    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.primaryField?.status)
      },

      set ({ value }) {
        this.stateStatus = value
      }
    },
  },

  methods: {
    ...helperMethods,

    async submit () {
      if (this.isNewPrimaryField) {
        await this.storePrimaryField({ ...this.primaryField })
      } else {
        await this.updatePrimaryField({ ...this.primaryField })
      }

      this.$emit('refresh-list')
      this.close()
    },

    async destroy () {
      if (
        !(await swal({
          title: 'Delete Primary Field',
          text: 'Are you sure you want to delete this primary field?',
          buttons: {
            cancel: { text: 'No', value: false, visible: true },
            confirm: {
              text: 'Yes',
              value: true,
              visible: true,
              closeModal: true,
            },
          },
          dangerMode: true,
          closeOnClickOutside: false,
        }))
      ) {
        return
      }

      await this.deletePrimaryField(this.primaryField.id)

      this.$emit('refresh-list')
      this.close()
    },
  }
}
</script>
