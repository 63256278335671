<template>
  <fragment>
    <app-header title="CPD Bodies">
      <template #actions>
        <button class="btn btn-md btn-primary" @click="openCreateCpdBodyDrawer">
          <i class="far fa-plus" /> CPD Body
        </button>
      </template>
    </app-header>

    <div class="container">
      <cpd-bodies-list-filters v-model="params" />

      <c-infinite-scroll
        as="ul"
        class="list-data list-data-setting-topic-categories has-button"
        @load="load"
      >
        <li class="data-heading d-none d-lg-block">
          <div class="row">
            <div class="col-lg">
              CPD Bodies
            </div>

            <div class="col col-status">
              Status
            </div>
          </div>
        </li>

        <list-item
          v-for="item in cpdBodiesFlattened"
          :key="`${item.is_profession ? 'profession' : 'accreditation-body'}-${item.id}`"
          :item="item"
          @open-edit-cpd-bodies-drawer="openEditCpdBodiesDrawer"
        />
      </c-infinite-scroll>
    </div>

    <cpd-bodies-drawer ref="cpdBodiesDrawer" @refresh="refresh" />
  </fragment>
</template>

<script>
import { stateMapper, helperComputed, helperMethods } from '@/vuex/modules/cpd-bodies'
import { compareQueryParams } from '@codium/codium-vue'
import ListItem from '@/components/cpd-bodies/CpdBodiesListItem'
import CpdBodiesListFilters from '@/components/cpd-bodies/CpdBodiesListFilters'
import CpdBodiesDrawer from '@/components/cpd-bodies/CpdBodiesDrawer'

export default {
  mixins: [stateMapper],

  components: {
    ListItem,
    CpdBodiesListFilters,
    CpdBodiesDrawer,
  },

  data () {
    return {
      params: null,
    }
  },

  watch: {
    params: compareQueryParams(async function (params) {
      await this.getCpdBodies({ params, fresh: true })
    })
  },

  beforeDestroy () {
    this.resetCpdBodies()
  },

  computed: {
    ...helperComputed,

    cpdBodiesFlattened () {
      const flattened = []

      this.cpdBodies.forEach((profession) => {
        flattened.push({
          ...profession,
          is_profession: true,
        })

        if ((profession.cpd_bodies?.length ?? 0) > 0) {
          profession.cpd_bodies.forEach((cpdBody) => {
            flattened.push({
              ...cpdBody,
              is_profession: false,
            })
          })
        }
      })

      return flattened
    },
  },

  methods: {
    ...helperMethods,

    async load (done) {
      const { params } = this

      try {
        await this.getCpdBodies({ params })

        done()
      } catch {
        done(true)
      }
    },

    refresh () {
      this.getCpdBodies({ params: this.params, fresh: true })
    },

    openCreateCpdBodyDrawer () {
      this.resetCpdBody()
      this.$refs.cpdBodiesDrawer.open()
    },

    openEditCpdBodiesDrawer (cpdBody) {
      this.setCpdBody(cpdBody)
      this.$refs.cpdBodiesDrawer.open()
    },
  }
}
</script>
