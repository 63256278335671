<template>
  <multiselect
    v-bind="attrs"
    :options="optionsFiltered"
    :show-labels="false"
    label="name"
    track-by="id"
    v-model="selected"
  />
</template>

<script>
import { http } from '@/utils'
import { CInput, toast } from '@chameleon/chameleon-vue'

export default {
  extends: CInput,

  props: {
    value: Number,
    showDisabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      options: []
    }
  },

  computed: {
    isInput () {
      return false
    },

    selected: {
      get () {
        return this.options.find(({ id }) => id === this.value) || null
      },

      set (value) {
        this.$emit('input', value ? value.id : null)

        this.$emit('blur')
      }
    },

    optionsFiltered () {
      return this.options.filter(option => {
        if (!this.showDisabled) {
          return option.status === 'enabled'
        }
        return true
      })
    }
  },

  created () {
    this.getList()
  },

  methods: {
    async getList () {
      try {
        const response = await http.get('topics')
        this.options = response.data.data
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    }
  },
}
</script>
