<template>
  <c-data-filters>
    <div class="row">
      <div class="col-lg-8 col-xl-9 mb-3">
        <div class="mb-3">
          <label>Search</label>

          <c-input type="text" :value="filters.search" @input="updateSearch">
            <i slot="prepend" class="fal fa-search" />
          </c-input>
        </div>
      </div>

      <div class="col-lg-4 col-xl-3 mb-3">
        <div class="mb-3">
          <label>Status</label>

          <c-input
            as="multiselect"
            placeholder
            :options="statusOptions"
            :preselect-first="true"
            :show-labels="false"
            :allow-empty="false"
            label="name"
            v-model="status"
          >
          </c-input>
        </div>
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'

export default {
  mixins: [useQueryParamFilters()],

  data () {
    return {
      filters: {
        search: null,
        status: null,
      },

      statusOptions: [
        { name: 'All', value: null },
        { name: 'Published', value: 'published' },
        { name: 'Archived', value: 'archived' },
      ],

      debouncedUpdateSearch: null,
    }
  },

  created () {
    this.debouncedUpdateSearch = debounce((value) => {
      this.filters.search = value
    }, 500)
  },

  computed: {
    status: {
      get () {
        return this.statusOptions.find(({ value }) => value === this.filters.status)
      },

      set ({ value }) {
        this.filters.status = value
      }
    },
  },

  methods: {
    updateSearch (value) {
      this.debouncedUpdateSearch(value)
    }
  }
}
</script>
