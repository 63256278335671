<template>
  <c-data-filters>

    <!-- search -->
    <template #basic>
      <label class="sr-only">Search</label>
      <c-input type="text" :value="filters.search" @input="updateSearch">
        <i slot="prepend" class="fal fa-search" />
      </c-input>
    </template>

    <div class="row">
      <div class="col-lg-8 col-xl-6 mb-3">
        <label>Search</label>
        <c-input type="text" :value="filters.search" @input="updateSearch">
          <i slot="prepend" class="fal fa-search" />
        </c-input>
      </div>

      <div class="col-lg-4 col-xl-3 mb-3">
        <label>Profession</label>
        <profession-select v-model="filters.professions" multiple />
      </div>

      <div class="col-lg-4 col-xl-3 mb-3">
        <label>Status</label>
        <single-select v-model="filters.status" :options="statusOptions" />
      </div>
    </div>
  </c-data-filters>
</template>

<script>
import { useQueryParamFilters } from '@codium/codium-vue'
import { debounce } from 'lodash'
import SingleSelect from '@/components/forms/SingleSelect.vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'

export default {
  mixins: [useQueryParamFilters()],

  components: {
    SingleSelect,
    ProfessionSelect,
  },

  data () {
    return {
      filters: {
        search: null,
        professions: null,
        status: 'all',
      },
      statusOptions: [
        { label: 'All', value: null },
        { label: 'Enabled', value: 'enabled' },
        { label: 'Disabled', value: 'disabled' },
      ]
    }
  },

  computed: {
    updateSearch () {
      return debounce((value) => {
        this.filters.search = value
      }, 500)
    }
  }
}
</script>
