<template>
  <li :class="{ 'sub': !item.is_profession }">
    <a @click.prevent="openEdit">
      <div class="row">
        <div class="col-lg">
          <img v-if="!item.is_profession" :src="item.logo_url" width="150" height="150" alt="" class="thumbnail">

          <p><span v-if="item.body_acronym">{{ item.body_acronym }} - </span> {{ item.name }}</p>
        </div>

        <div v-if="!item.is_profession" class="col col-status">
          <span v-if="item.status === 'published'" class="status status-success">Published</span>
          <span v-else class="status status-danger">Archived</span>
        </div>
      </div>
    </a>

    <button
      v-if="!item.is_profession"
      type="button"
      class="btn btn-sm text-info"
      @click="openEdit"
    >
      <i class="far fa-pencil-alt" />
    </button>
  </li>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    }
  },

  methods: {
    openEdit () {
      if (this.item.is_profession) {
        return
      }

      this.$router.push({
        name: 'settings.accreditation-bodies.single',
        params: {
          accreditationBodyId: this.item.id,
        },
      })
    }
  },
}
</script>
