<template>
  <fragment>
    <app-header :title="pageTitle">
      <template #actions>
        <a
          v-c-modal:topicsSingleDrawer
          class="btn btn-md btn-primary"
        >
          <i class="far fa-plus" /> Topic
        </a>
        <a
          v-c-modal:subtopicsSingleDrawer
          class="btn btn-md btn-primary ms-1"
        >
          <i class="far fa-plus" /> Subtopic
        </a>
      </template>
    </app-header>

    <div class="container">
      <!-- filters -->
      <list-filters v-model="params" />

      <div class="scroll-container">
        <ul class="list-data list-data-setting-topic-categories has-button">
          <li class="data-heading d-none d-lg-block">
            <div class="row">
              <div class="col-lg">Topic</div>
              <div class="col col-profession">Profession</div>
              <div class="col col-courses">Courses</div>
              <div class="col col-status">Status</div>
            </div>
          </li>
          <template v-for="item in items">
            <li :key="`topic-${item.id}`">
              <a v-c-modal:topicsSingleDrawer="{ item: item }">
                <div class="row">
                  <!-- name -->
                  <div class="col-lg">
                    <p>{{ item.name }}</p>
                  </div>

                  <!-- profession -->
                  <div class="col col-profession">
                    <p>{{ item.profession_name }}</p>
                  </div>

                  <!-- courses count -->
                  <div class="col col-courses">
                    <p>{{ item.courses_count }}</p>
                  </div>

                  <!-- status -->
                  <div class="col col-status">
                    <span class="status" :class="statusClass(item.status)">{{
                      status(item.status)
                    }}</span>
                  </div>
                </div>
              </a>
              <button
                type="button"
                v-c-modal:topicsSingleDrawer="{ item: item }"
                class="btn btn-sm text-info"
              >
                <i class="far fa-pencil-alt" />
              </button>
            </li>
            <li
              class="sub"
              v-for="child in item.children"
              :key="`topic-${item.id}-child-${child.id}`"
            >
              <a v-c-modal:subtopicsSingleDrawer="{ item: child }">
                <div class="row">
                  <!-- name -->
                  <div class="col-lg">
                    <p>{{ child.name }}</p>
                  </div>

                  <!-- courses count -->
                  <div class="col col-courses">
                    <p>{{ child.courses_count }}</p>
                  </div>

                  <!-- status -->
                  <div class="col col-status">
                    <span class="status" :class="statusClass(child.status)">{{
                      status(child.status)
                    }}</span>
                  </div>
                </div>
              </a>
              <button
                type="button"
                v-c-modal:subtopicsSingleDrawer="{ item: child }"
                class="btn btn-sm text-info"
              >
                <i class="far fa-pencil-alt" />
              </button>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <topics-single-drawer ref="topicsSingleDrawer" @refresh="refresh"/>
    <subtopics-single-drawer ref="subtopicsSingleDrawer" @refresh="refresh"/>
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/topics'
import ListFilters from '@/components/topics/TopicsListFilters'
import TopicsSingleDrawer from '@/components/topics/TopicsSingleDrawer'
import SubtopicsSingleDrawer from '@/components/topics/SubtopicsSingleDrawer'
import { compareQueryParams } from '@codium/codium-vue'
import { toast } from '@chameleon/chameleon-vue'

export default {
  components: {
    ListFilters,
    TopicsSingleDrawer,
    SubtopicsSingleDrawer
  },

  data () {
    return {
      params: null
    }
  },

  async created () {
    this.getList()
  },

  watch: {
    params: compareQueryParams(async function () {
      await this.getList(true)
    })
  },

  computed: {
    ...helperComputed,

    pageTitle () {
      return 'Topics and Subtopics'
    },

    items () {
      return this.topics
    }
  },

  beforeDestroy () {
    this.resetTopics()
  },

  methods: {
    ...helperMethods,

    async getList (fresh) {
      try {
        await this.getTopics({ params: this.params, fresh: fresh })
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    async refresh () {
      await this.getList(true)
    },

    status (status) {
      return status === 'enabled' ? 'Enabled' : 'Disabled'
    },
    statusClass (status) {
      return status === 'enabled' ? 'status-success' : 'status-danger'
    }
  }
}
</script>
