<template>
  <fragment>
    <app-header :title="pageTitle">
      <template #actions>
        <a
          v-c-modal:universitiesSingleDrawer
          class="btn btn-md btn-primary"
          ><i class="far fa-plus"></i>
          University
        </a>
      </template>
    </app-header>
    <div class="container">
      <div class="card card-data-builder">
        <ul id="horizontal-nav" class="nav nav-horizontal">
          <router-link tag="li" :to="{ name: 'settings.universities' }" class="nav-item" exact>
            <a class="nav-link">Published</a>
          </router-link>
          <router-link tag="li" :to="{ name: 'settings.universities', query: { status: 'archived' } }" class="nav-item" exact>
            <a class="nav-link">Archived</a>
          </router-link>
        </ul>
        <!-- list -->
        <c-data-list :items="universities" @load="load" class="has-button">
          <template #columns>
          <div class="col">
            Name
          </div>
          <div class="col">
            Country
          </div>
        </template>
          <template #default="{ item }">
            <university-list-item :item="item" @openDrawer="openDrawer" @updateStatus="updateStatus"/>
          </template>
        </c-data-list>
      </div>
    </div>
    <universities-single-drawer ref="universitiesSingleDrawer" @refresh="refresh"/>
  </fragment>
</template>

<script>
import { helperMethods, helperComputed } from '@/vuex/modules/universities'
import UniversitiesSingleDrawer from '@/components/universities/UniversitiesSingleDrawer'
import UniversityListItem from '@/components/universities/UniversityListItem'
import { call } from 'vuex-pathify'

export default {
  components: {
    UniversitiesSingleDrawer,
    UniversityListItem
  },
  watch: {
    '$route.query': {
      handler: async function () {
        await this.getUniversities({ params: { status: this.$route.query.status }, fresh: true })
      },
    }
  },

  computed: {
    ...helperComputed,

    pageTitle () {
      return 'Universities'
    },
  },

  beforeDestroy () {
    this.resetUniversities()
  },

  methods: {
    ...helperMethods,
    toggleStatus: call('universities/toggleStatus'),
    async load (done) {
      try {
        await this.getUniversities({ params: this.$route.query })
        done()
      } catch {
        done(true)
      }
    },
    async refresh () {
      await this.getUniversities({ params: this.$route.query, fresh: true })
    },
    openDrawer (universityId) {
      this.$refs.universitiesSingleDrawer.open({ universityId: universityId })
    },
    async updateStatus (universityId) {
      this.toggleStatus({ id: universityId })
    }
  }
}
</script>
