<template>
    <fragment>
      <a href="#"  @click="$emit('openDrawer', item.id)">
          <div class="row">
              <div class="col">
                {{item.name}}
              </div>
              <div class="col">
                {{item.country}}
              </div>
          </div>
      </a>
      <button type="button" class="btn btn-sm text-warning" @click="$emit('updateStatus', item.id)">
        <i class="far fa-archive" v-if="item.status === 'published'"></i>
        <i class="far fa-trash-restore" v-else></i>
      </button>
    </fragment>
</template>

<script>
export default {
  props: {
    item: {
      required: true,
      type: Object
    }
  },
}
</script>
