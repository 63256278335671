<template>
  <validation-observer slim @submit.native.prevent ref="validation">
    <c-modal :title="drawertitle" fade drawer>

      <!-- name -->
      <validation-provider tag="div" class="mb-4" name="topic name" vid="item.name" rules="required">
        <label>Topic Name</label>
        <c-input type="text" v-model="item.name" />
        <c-help-block/>
      </validation-provider>

      <!-- profession -->
      <validation-provider tag="div" class="mb-4" name="profession" vid="item.profession" rules="required">
        <label>Profession</label>
        <profession-select v-model="item.profession_id" />
        <c-help-block/>
      </validation-provider>

      <!-- status -->
      <validation-provider tag="div" class="mb-4" vid="item.status" name="status" rules="required">
        <label class="form-label">Status</label>
        <single-select v-model="item.status" :options="optionsStatus" :preselect="false"/>
        <c-help-block />
      </validation-provider>

      <!-- show on homepage -->
      <div class="form-check mb-4">
        <input class="form-check-input" type="checkbox" v-model="item.show_on_homepage" id="check-marketing" />
        <label class="form-check-label" for="check-marketing">
          Show on home page
        </label>
      </div>

      <!-- subtopics count -->
      <div class="row mb-4" v-if="editingTopic && item.children">
        <div class="col">
          <span class="form-label">Subtopics</span>
        </div>
        <div class="col-auto">{{ subTopicsCount }}</div>
      </div>

      <!-- courses count -->
      <div class="row mb-4" v-if="editingTopic">
        <div class="col">
          <span class="form-label">Total Courses</span>
        </div>
        <div class="col-auto">{{ item.courses_count }}</div>
      </div>

      <!-- footer -->
      <template #footer>
        <c-button v-if="editingTopic" variant="outline-info" @click="handleDelete" class="btn-outline-danger me-auto">Delete Topic</c-button>
        <c-button variant="outline-info" @click="close">Cancel</c-button>
        <c-button validate variant="success" @click="save">Save</c-button>
      </template>

    </c-modal>
  </validation-observer>
</template>

<script>
import { CModal, toast } from '@chameleon/chameleon-vue'
import { http } from '@/utils'
import SingleSelect from '@/components/forms/SingleSelect.vue'
import ProfessionSelect from '@/components/professions/ProfessionSelect.vue'
import swal from 'sweetalert'

export default {
  extends: CModal,

  components: {
    SingleSelect,
    ProfessionSelect,
  },

  data () {
    return {
      drawerState: 'closed',
      item: {
        id: null,
        profession_id: null,
        name: null,
        status: 'enabled',
        show_on_homepage: false,
        courses_count: 0,
      },
      optionsStatus: [
        { label: 'Enabled', value: 'enabled' },
        { label: 'Disabled', value: 'disabled' },
      ]
    }
  },

  mounted () {
    this.addEventListener('open', async () => {
      if (this.drawerState === 'closed') {
        this.$emit('opened')
      }
      this.modalState = 'open'
    })
    this.addEventListener('close', async () => {
      if (this.drawerState === 'open') {
        this.$emit('closed')
      }
      this.modalState = 'closed'
    })
  },

  computed: {
    drawertitle () {
      return this.editingTopic ? 'Edit Topic' : 'Add Topic'
    },
    editingTopic () {
      return this.item.id
    },
    subTopicsCount () {
      return this.item.children ? this.item.children.length : 0
    },
  },

  methods: {
    async save () {
      try {
        const payload = this.item
        if (this.editingTopic) {
          if (payload.status === 'disabled') {
            const result = await swal({
              icon: 'warning',
              title: 'Disabling Topic',
              text: 'Disabling this topic will disable all subtopics within it.',
              buttons: {
                cancel: { text: 'Cancel', value: false, visible: true, className: 'swal-button swal-button--cancel btn btn-outline-dark' },
                ok: { text: 'Continue', value: true, visible: true, className: 'swal-button swal-button--contact btn btn-primary' },
              },
              dangerMode: true
            })
            if (result) {
              await http.put('/topics/' + this.item.id, payload)
              toast('Topic updated', 'success')
            }
          } else {
            await http.put('/topics/' + this.item.id, payload)
            toast('Topic updated', 'success')
          }
        } else {
          await http.post('/topics', payload)
          toast('Topic added', 'success')
        }
        this.$emit('refresh')
        this.close()
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast(error.response.data.message, 'danger')
        } else {
          throw error
        }
      }
    },

    async handleDelete () {
      const result = await swal({
        icon: 'warning',
        title: 'Are you sure you want to delete this topic?',
        buttons: {
          cancel: { text: 'Cancel', value: false, visible: true, className: 'swal-button swal-button--cancel btn btn-outline-dark' },
          ok: { text: 'Delete', value: true, visible: true, className: 'swal-button swal-button--contact btn btn-danger' },
        },
        dangerMode: true
      })
      if (result) {
        try {
          await http.delete('/topics/' + this.item.id)
          toast('Topic deleted', 'success')
          this.$emit('refresh')
          this.close()
        } catch (error) {
          if (error.response && error.response.status === 400) {
            toast(error.response.data.message, 'danger')
          } else {
            throw error
          }
        }
      }
    }
  }
}
</script>
